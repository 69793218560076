import React from "react";
const CatalogDownloadListSK = (props) => {
	return (
		<li className="catalog-li ">
			<a className={"medium"} href={props.link}>
				<div className="catalog-list flex">
					<div className="catalog-list-img">
						<img src={props.image} alt={props.alt} />
					</div>
					<div className="catalog-list-content">
						<div className="catalog-list-content-title">
							<p>{props.title_name}</p>
						</div>
					</div>
				</div>
			</a>
		</li>
	);
};
export default CatalogDownloadListSK;
